import { gql } from 'apollo-angular';
import { OverviewFeedback } from '@frontend/models';

export interface GraphQLResponseOverviewFeedback {
  overviewFeedback: OverviewFeedback;
}

export const GET_OVERVIEW_FEEDBACK = gql`
  query GetOverviewFeedback($accessId: String!) {
    overviewFeedback(accessId: $accessId) {
      waitingForFeedback
    }
  }
`;