import {NgModule} from '@angular/core';
import {HttpLink} from "apollo-angular/http";
import {ApolloClientOptions, InMemoryCache} from "@apollo/client/core";
import {environment} from "@frontend/envs/environment";
import {APOLLO_OPTIONS, ApolloModule} from "apollo-angular";

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
    return {
        link: httpLink.create({uri: environment.backendBaseUrl}),
        cache: new InMemoryCache({addTypename: false}),
    };
}

@NgModule({
    imports: [ApolloModule],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApollo,
            deps: [HttpLink],
        },
    ],
})
export class GraphQlValuationModule {
}