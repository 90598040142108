import { Pipe, PipeTransform } from '@angular/core';
import { OrderType } from '@frontend/models';

export const transformOrderType = (value?: OrderType | null): string => {
  if (!value) {
    return '';
  }

  switch (value) {
    case 'buy':
      return 'Kauf';
    case 'rent':
      return 'Miete';
  }
}

@Pipe({ name: 'orderType' })
export class OrderTypePipe implements PipeTransform {
  transform(value?: OrderType | null): string {
    return transformOrderType(value)
  }
}
