<div [ngClass]="{
  'theme-propup': isPropupTheme$ | async,
  'theme-remax': isRemaxTheme$ | async,
  'theme-sulek': isSulekTheme$ | async,
  'theme-wohncloud': isWohnCloudTheme$ | async,
  'theme-grasl': isGraslTheme$ | async,
  'theme-immo4': isImmo4Theme$ | async,
  'theme-immobilienMelk': isImmobilienMelkTheme$ | async,
  'theme-altera': isAlteraTheme$ | async,
  'theme-finova': isFinovaTheme$ | async,
  'theme-hagsteiner': isHagsteinerTheme$ | async,
  'theme-qualis': isQualisTheme$ | async,
  'theme-edelweiss': isEdelweissTheme$ | async,
  'theme-remaxCollection': isRemaxCollectionTheme$ | async,
  'theme-remaxClassic': isRemaxClassicTheme$ | async,
  'theme-prosch': isProschTheme$ | async,
  'theme-ahImmobilien': isAhImmobilienTheme$ | async,
  'theme-aaaaImmobilien': isAaaaImmobilienTheme$ | async,
  'theme-norisk': isNoriskTheme$ | async,
  'theme-mayrImmo': isMayrImmoTheme$ | async,
  'theme-eraImmo': isEraImmoTheme$ | async,
  'theme-sonnberger': isSonnbergerTheme$ | async,
  'theme-virimo': isVirimoTheme$ | async,
}">
  <router-outlet></router-outlet>
</div>