import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { OwnerOverviewActions } from './action-types';
import { OwnerOverviewFeatureState, ownerOverviewSelectors } from './index';
import { BusinessCustomerWithCustomerDetails, Customer } from '@frontend/models';

@Injectable({ providedIn: 'root' })
export class OwnerOverviewStoreFacade {
  docsOverview$ = this._store$.select(ownerOverviewSelectors.docsOverview);
  docsOverviewLoading$ = this._store$.select(ownerOverviewSelectors.docsOverviewLoading);
  docsOverviewError$ = this._store$.select(ownerOverviewSelectors.docsOverviewError);
  ownersData$ = this._store$.select(ownerOverviewSelectors.ownersData);
  rentalOfferPreviewData$ = this._store$.select(ownerOverviewSelectors.rentalOfferPreviewData);
  buyingOfferPreviewData$ = this._store$.select(ownerOverviewSelectors.buyingOfferPreviewData);
  exclusiveAgreementPreviewData$ = this._store$.select(ownerOverviewSelectors.exclusiveAgreementPreviewData);

  constructor(private readonly _store$: Store<OwnerOverviewFeatureState>) { }

  loadOwnerOverview(ownerPortalId: string): void {
    this._store$.dispatch(OwnerOverviewActions.LoadOwnerOverview({ ownerPortalId }));
  }

  loadRentalOfferPreviewData(rentalOfferId: string): void {
    this._store$.dispatch(OwnerOverviewActions.LoadRentalOfferPreviewData({ id: rentalOfferId }));
  }

  loadExclusiveAgreementPreviewData(exclusiveAgreementId: string): void {
    this._store$.dispatch(OwnerOverviewActions.LoadExclusiveAgreementPreviewData({ id: exclusiveAgreementId }));
  }

  loadBuyingOfferPreviewData(buyingOfferId: string): void {
    this._store$.dispatch(OwnerOverviewActions.LoadBuyingOfferPreviewData({ id: buyingOfferId }));
  }

  updateRentalOfferDocumentLink(url: string): void {
    this._store$.dispatch(OwnerOverviewActions.UpdateRentalOfferDocumentLink({ url }));
  }

  updateBuyingOfferDocumentLink(url: string): void {
    this._store$.dispatch(OwnerOverviewActions.UpdateBuyingOfferDocumentLink({ url }));
  }

  updateCustomersData(data: { customers: Customer[], businessCustomer?: BusinessCustomerWithCustomerDetails }): void {
    this._store$.dispatch(OwnerOverviewActions.UpdateCustomersData({
      payload: {
        customers: data.customers,
        businessCustomer: data.businessCustomer,
      }
    }));
  }

  updateLanguage$(ownerPortalId: string, language: string): void {
    this._store$.dispatch(OwnerOverviewActions.UpdateOwnerPortalLanguage({ ownerPortalId, language }));
  }
}
