import { gql } from 'apollo-angular';
import {Document} from "@frontend/models";

export interface GraphQLResponseDocuments {
    documents: Document[];
}

export const UPLOAD_DOCUMENT = gql`
    mutation UploadDocument($document: DocumentUploadInfo!) {
        uploadDocument(document: $document) {
            id
            parentId
            type
            data
        }
    }
`;