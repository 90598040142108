import { gql } from 'apollo-angular';

export interface GraphQLResponseDownloadAllDocuments {
  downloadAllDocuments: string;
}

export const DOWNLOAD_ALL_DOCUMENTS = gql`
    query DownloadAllDocuments($filesToZip: [String!]!, $fileNames: [String!]!) {
        downloadAllDocuments(filesToZip: $filesToZip, fileNames: $fileNames)
    }
`;