import { gql } from 'apollo-angular';
import { OwnerExclusiveAgreementPreviewData } from '@frontend/models';

export interface GraphQLResponseOwnerExclusiveAgreementPreviewData {
  exclusiveAgreementPreviewData: OwnerExclusiveAgreementPreviewData;
}

export const GET_OWNER_EXCLUSIVE_AGREEMENT_PREVIEW_DATA = gql`
  query GetOwnerExclusiveAgreementPreviewData($exclusiveAgreementId: String!) {
    exclusiveAgreementPreviewData(exclusiveAgreementId: $exclusiveAgreementId) {
      documentPreviewUrl
      requiredDocuments
      uploadedDocuments {
        id
        storageUrl
        description
        type
        title
        documentFrom
      }
    }
  }
`;