import {Injectable} from "@angular/core";
import {Apollo} from "apollo-angular";
import {map, Observable} from "rxjs";
import {CREATE_TRACING, GraphQLResponseCreateTracing} from "@frontend/graphql/mutation";

@Injectable({ providedIn: 'root' })
export class TracingService {
    constructor(private readonly _apollo: Apollo) { }


    createTracing$(feature: string, customerId: string): Observable<boolean> {
        return this._apollo.mutate<
            GraphQLResponseCreateTracing,
            { feature: string, customerId: string }
        >({
            mutation: CREATE_TRACING,
            variables: { feature, customerId },
        }).pipe(map((m: any) => m.data.createTracing));
    }

}