import { Pipe, PipeTransform } from '@angular/core';
import { ObjectType } from '@frontend/models';

export const transformObjectType = (value?: ObjectType | null): string => {
  if (!value) {
    return '';
  }

  switch (value) {
    case 'flat':
      return 'Wohnung';
    case 'house':
      return 'Haus';
    case 'premises':
      return 'Grundstück';
    case 'businessObject':
      return 'Geschäft';
    case 'specialObject':
      return 'Sonderobjekt';
  }
}

@Pipe({ name: 'objectType' })
export class ObjectTypePipe implements PipeTransform {
  transform(value?: ObjectType | null): string {
    return transformObjectType(value)
  }
}
