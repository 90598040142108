import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';

export const featureGuard = (snap: ActivatedRouteSnapshot) => {
  const router = inject(Router);

  if (!snap.data['allow']) {
    router.navigateByUrl(``)
  }

  return !!snap.data['allow'];
};