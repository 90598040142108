import { gql } from 'apollo-angular';

export interface GraphQLResponseSignOwnerRentalOfferDocument {
  signOwnerRentalOfferDocument: { storageUrl: string };
}

export const SIGN_OWNER_RENTAL_OFFER_DOCUMENT = gql`
  mutation SignRentalOfferDocument($rentalOfferId: String!, $landlordAcceptance: String!) {
    signOwnerRentalOfferDocument(rentalOfferId: $rentalOfferId, landlordAcceptance: $landlordAcceptance) {
      storageUrl
    }
  }
`;