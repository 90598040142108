import * as fromReducer from './reducer';
import {ActionReducerMap, createFeatureSelector, createSelector} from "@ngrx/store";

export interface PublicPropertyOverviewFeatureState {
    publicPropertyOverview: fromReducer.State;
}

export const reducers: ActionReducerMap<PublicPropertyOverviewFeatureState> = {
    publicPropertyOverview: fromReducer.reducer,
};

export const getPublicPropertyOverviewState = createFeatureSelector<fromReducer.State>('public-property-overview');

export const publicPropertyOverviewSelectors = {
    publicPropertyOverview: createSelector(getPublicPropertyOverviewState, fromReducer.getPublicPropertyOverview),
    publicPropertyOverviewError: createSelector(getPublicPropertyOverviewState, fromReducer.getPublicPropertyOverviewError),
    publicPropertyOverviewLoading: createSelector(getPublicPropertyOverviewState, fromReducer.getPublicPropertyOverviewLoading),
};

export {PublicPropertyOverviewActions} from './action-types';
export {PublicPropertyOverviewStoreFacade} from './public-property-overview-store.facade';
export {PublicPropertyOverviewStoreModule} from './public-property-overview-store.module';