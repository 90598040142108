<div class="m-auto px-4 flex background-img" style="height: 100vh">
    <div class="w-full text-center self-center">
        <div class="flex justify-center my-8">
            <a href="https://www.propup.at/" target="_blank">
                <img class="w-80" src="/assets/images/propup-logo.png" alt="PROPUP Logo" />
            </a>
        </div>

        <h1 class="text-4xl font-bold lg:text-6xl mb-10 tracking-widest text-white">
            Kundenportal
        </h1>
        <h2 class="text-white tracking-widest">
            Coming Soon
        </h2>
    </div>
</div>