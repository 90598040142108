import { gql } from 'apollo-angular';
import { RentalOfferPreviewDocument } from '@frontend/models';

export interface GraphQLResponseRentalOfferDocumentPreview {
  rentalOfferDocumentPreview: RentalOfferPreviewDocument;
}

export const GET_RENTAL_OFFER_DOCUMENT_PREVIEW = gql`
  query GetRentalOfferDocumentPreview($id: String!) {
    rentalOfferDocumentPreview(id: $id) {
      storageUrl
    }
  }
`;