import { gql } from 'apollo-angular';

export interface GraphQLResponseSendViewingPass {
  sendViewingPass: string;
}

export const SEND_VIEWING_PASS = gql`
  mutation SendViewingPass($accessId: String!) {
    sendViewingPass(accessId: $accessId)
  }
`;