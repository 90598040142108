import {createAction, props} from "@ngrx/store";
import {PropertyOverview} from "@frontend/models";

export const LoadPublicPropertyOverview = createAction(
    '[Public Property Overview] Load Public Property Overview',
    props<{ objectId: string }>(),
);

export const LoadPublicPropertyOverviewFailed = createAction('[Public Property Overview] Failed to load Public Property Overview');
export const LoadPublicPropertyOverviewSucceeded = createAction(
    '[Public Property Overview] Succeeded to load Public Property Overview',
    props<{ payload: PropertyOverview }>()
);