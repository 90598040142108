import { gql } from 'apollo-angular';

export interface GraphQlResponseSaveStorageUrl {
  saveStorageUrl: string;
}

export const SAVE_STORAGE_URL = gql`
  mutation SaveStorageUrl($saveStorageUrl: SaveStorageUrlInput!, $type: String!, $accessId: String!) {
    saveStorageUrl(saveStorageUrl: $saveStorageUrl, type: $type, accessId: $accessId)
  }
`;

export interface GraphQlResponseSaveExclusiveAgreementUrl {
  saveStorageUrl: string;
}

export const SAVE_EXCLUSIVE_AGREEMENT_URL = gql`
  mutation SaveExclusiveAgreementStorageUrl($exclusiveAgreementId: String!, $saveStorageUrl: SaveStorageUrlInput!) {
    saveExclusiveAgreementStorageUrl(exclusiveAgreementId: $exclusiveAgreementId, saveStorageUrl: $saveStorageUrl)
  }
`;
