import { gql } from 'apollo-angular';
import { ViewingPassPreviewDocument} from '@frontend/models';

export interface GraphQLResponseViewingPassDocumentPreview {
    viewingPassDocumentPreview: ViewingPassPreviewDocument;
}

export const GET_VIEWING_PASS_DOCUMENT_PREVIEW = gql`
    query GetViewingPassDocumentPreview($id: String!) {
        viewingPassDocumentPreview(id: $id) {
            storageUrl
        }
    }
`;