import { gql } from 'apollo-angular';

export interface GraphQLResponseGenerateRentalOfferDocument {
  generateRentalOfferDocument: { storageUrl: string };
}

export const GENERATE_RENTAL_OFFER_DOCUMENT = gql`
  mutation GenerateRentalOfferDocument($accessId: String!) {
    generateRentalOfferDocument(accessId: $accessId) {
      storageUrl
    }
  }
`;