import { VerifyCodeModel } from '@frontend/models';
import { gql } from 'apollo-angular';

export interface GraphQLResponseRequestSmsVerification {
  requestSmsVerification: VerifyCodeModel;
}

export const REQUEST_SMS_VERIFICATION = gql`
  mutation RequestSmsVerification($model: SmsVerificationInput!) {
    requestSmsVerification(model: $model) {
      id
    }
  }
`;