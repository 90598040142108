import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { OwnerOverviewEffects } from "./effects";
import * as fromReducer from './reducer';

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    EffectsModule.forFeature([OwnerOverviewEffects]),
    StoreModule.forFeature('owner-overview', fromReducer.reducer),
  ]
})
export class OwnerOverviewStoreModule { }
