import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DateAdapter, MAT_DATE_LOCALE, } from "@angular/material/core";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { GraphQLModule } from "@frontend/graphql";
import { FormattingModule } from "@frontend/formatting";
import { HomeModule } from "@frontend/pages/home";
import { PropertyOverviewStoreModule } from "@frontend/store/property-overview";

import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";

import * as Sentry from '@sentry/angular-ivy';
import { Router } from "@angular/router";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
  MomentDateAdapter
} from "@angular/material-moment-adapter";
import { OwnerOverviewStoreModule } from "@frontend/store/owner-overview";
import {GraphQlValuationModule} from "./valuation/graphql";
import {PublicPropertyOverviewStoreModule} from "@frontend/store/public-property-overview";
import {TranslationRootModule} from "./i18n/TranslationRootModule";


registerLocaleData(localeDe);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    GraphQLModule,
    GraphQlValuationModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    FormattingModule,
    MatMomentDateModule,
    HomeModule,
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    PropertyOverviewStoreModule,
    PublicPropertyOverviewStoreModule,
    OwnerOverviewStoreModule,
    NgxSkeletonLoaderModule.forRoot({ animation: 'pulse' }),
    TranslationRootModule,
  ],
  exports: [],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: MAT_DATE_LOCALE, useValue: 'de-AT' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useFactory: () => {
        const dateAdapter = new MomentDateAdapter('de-at', { useUtc: true });
        dateAdapter.getFirstDayOfWeek = () => 1;
        dateAdapter.format = (date, displayFormat) => {
          if (!date) return '';
          return date.format('DD.MM.YYYY');
        };
        return dateAdapter;
      },
      deps: []
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false }),
    },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) { }
}