import { Component } from '@angular/core';

@Component({
  selector: 'home',
  templateUrl: 'home.component.html',
  styles: [`
    .background-img {
      background-image: url('/assets/images/background.png');
      background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
    }
  `],
})
export class HomeComponent { }