import { gql } from 'apollo-angular';
import { PropertyOverviewAgent } from '@frontend/models';

export interface GraphQLResponseAgentSettings {
  agentSettings: PropertyOverviewAgent;
}

export const GET_AGENT_SETTINGS = gql`
  query GetCustomerPortalById($objectId: String!) {
    agentSettings(objectId: $objectId) {
      id
      email
      firstName
      lastName
      picture
      officeName
      legalEntity
      isRemax
      themeConfig
      phone
      googleReviewLink
      countrySettings
      taxBase
    }
  }
`;