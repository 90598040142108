import { gql } from 'apollo-angular';

export interface GraphQLResponseGenerateOwnerBuyingOfferDocument {
  generateOwnerBuyingOfferDocument: { storageUrl: string };
}

export const GENERATE_OWNER_BUYING_OFFER_DOCUMENT = gql`
  mutation GenerateBuyingOfferDocument($buyingOfferId: String!) {
    generateOwnerBuyingOfferDocument(buyingOfferId: $buyingOfferId) {
      storageUrl
    }
  }
`;