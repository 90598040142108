import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {StoreModule} from "@ngrx/store";
import * as fromReducer from './reducer';
import {EffectsModule} from "@ngrx/effects";
import {PublicPropertyOverviewEffects} from "./effects";

@NgModule({
    imports: [
        CommonModule,
        MatSnackBarModule,
        StoreModule.forFeature('public-property-overview', fromReducer.reducer),
        EffectsModule.forFeature([PublicPropertyOverviewEffects]),
    ]
})
export class PublicPropertyOverviewStoreModule {
}
