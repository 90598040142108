
import { gql } from 'apollo-angular';
import {ViewingPassPreviewData} from '@frontend/models';

export interface GraphQLResponseViewingPassPreviewData {
  viewingPassPreviewData: ViewingPassPreviewData;
}

export const GET_VIEWING_PASS_PREVIEW_DATA = gql`
  query GetViewingPassPreviewData($id: String!) {
    viewingPassPreviewData(id: $id) {
      agent {
        id
        email
        firstName
        lastName
        picture
        officeName
        legalEntity
        isRemax
        themeConfig
        phone
      }
      address {
        country
        city
        state
        zipCode
        streetName
        buildingNumber
        doorNumber
      }
      state
      signedDocumentUrl
    }
  }
`;