import { SmsVerificationStatus } from '@frontend/models';
import { gql } from 'apollo-angular';

export interface GraphQLResponseVerifySms {
  verifySms: SmsVerificationStatus;
}

export const VERIFY_SMS = gql`
  mutation VerifySms($model: VerifyCodeInput!) {
    verifySms(model: $model) {
      isVerified
      message
    }
  }
`;