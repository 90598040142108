import { gql } from 'apollo-angular';
import { BuyingOfferPreviewDocument} from '@frontend/models';

export interface GraphQLResponseBuyingOfferDocumentPreview {
    buyingOfferDocumentPreview: BuyingOfferPreviewDocument;
}

export const GET_BUYING_OFFER_DOCUMENT_PREVIEW = gql`
    query GetBuyingOfferDocumentPreview($id: String!) {
        buyingOfferDocumentPreview(id: $id) {
            storageUrl
        }
    }
`;