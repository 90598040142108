
import { gql } from 'apollo-angular';
import { BuyingOfferPreviewData } from '@frontend/models';

export interface GraphQLResponseBuyingOfferPreviewData {
  buyingOfferPreviewData: BuyingOfferPreviewData;
}

export const GET_BUYING_OFFER_PREVIEW_DATA = gql`
  query GetBuyingOfferPreviewData($id: String!) {
    buyingOfferPreviewData(id: $id) {
      agent {
        id
        email
        firstName
        lastName
        picture
        officeName
        legalEntity
        isRemax
        themeConfig
        phone
        countrySettings
      }
      address {
        country
        city
        state
        zipCode
        streetName
        buildingNumber
        doorNumber
      }
      state
      signedDocumentUrl
      sellersAcceptance {
        sellerAcceptance
        changeAcceptanceUntil
        changeDetails
      }
    }
  }
`;