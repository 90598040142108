import { gql } from 'apollo-angular';
import { PropertyOverview } from '@frontend/models';

export interface GraphQLResponsePublicPropertyOverview {
    publicPropertyOverview: PropertyOverview;
}

export const GET_PROPERTY_OVERVIEW_BY_OBJECTID = gql`
    query GetPublicPropertyByObjectId($objectId: String!) {
        publicPropertyOverview(objectId: $objectId) {
            objectId
            agent {
                id
                email
                firstName
                lastName
                picture
                officeName
                legalEntity
                isRemax
                themeConfig
                phone
                googleReviewLink
            }
            property {
                id
                title
                objectType
                orderType
                agent {
                    id
                    email
                    firstName
                    lastName
                    phone
                }
                address {
                    country
                    city
                    state
                    zipCode
                    streetName
                    buildingNumber
                    doorNumber
                    stairway
                }
                roomsAndArea {
                    livingArea {
                        area
                        areaCa
                    }
                    baseArea {
                        area
                        areaCa
                    }
                    usableArea {
                        area
                        areaCa
                    }
                }
                price
                realtyPrice {
                    purchase_price {
                        currency
                        net
                        gross
                        vat_absolute
                        vat_percentage
                    }
                    rent {
                        currency
                        net
                        gross
                        vat_absolute
                        vat_percentage
                    }
                    parking {
                        currency
                        net
                        gross
                        vat_absolute
                        vat_percentage
                    }
                    garage {
                        currency
                        net
                        gross
                        vat_absolute
                        vat_percentage
                    }
                    operating {
                        currency
                        net
                        gross
                        vat_absolute
                        vat_percentage
                    }
                    heating {
                        currency
                        net
                        gross
                        vat_absolute
                        vat_percentage
                    }
                    hot_water {
                        currency
                        net
                        gross
                        vat_absolute
                        vat_percentage
                    }
                    cooling {
                        currency
                        net
                        gross
                        vat_absolute
                        vat_percentage
                    }
                    maintenance_funds {
                        currency
                        net
                        gross
                        vat_absolute
                        vat_percentage
                    }
                    elevator {
                        currency
                        net
                        gross
                        vat_absolute
                        vat_percentage
                    }
                    others {
                        currency
                        net
                        gross
                        vat_absolute
                        vat_percentage
                    }
                }
                creationDate
                images {
                    id
                    storageUrl
                    type
                    description
                    order
                }
                displayImages
            }
        }
    }
`;