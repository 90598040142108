import { gql } from 'apollo-angular';

import { PropertyOverview } from '@frontend/models';

export interface GraphQLResponseCreateCustomerPortal {
  createCustomerPortal: PropertyOverview;
}

export const CREATE_CUSTOMER_PORTAL = gql`
    mutation CreateCustomerPortal($objectId: String!, $customerId: String!, $customerEmail: String!) {
        createCustomerPortal(objectId: $objectId, customerId: $customerId, customerEmail: $customerEmail) {
          id
          userData {
            rentalOffer {
              documentPreviewUrl
            }
          }
            buyingOffer {
                data {
                    documentPreviewUrl
                }
            }
        }
    }
`;