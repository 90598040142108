import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  standalone: true,
  selector: 'app-loading',
  templateUrl: 'loading.component.html',
  styles: [`
    :host {
        min-height: 100vH;
        min-width: 100vW;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  `],
  imports: [MatProgressSpinnerModule],
})

export class LoadingComponent { }