import { gql } from 'apollo-angular';

export interface GraphQLResponseCreateTracing {
  tracing: boolean;
}

export const CREATE_TRACING = gql`
    mutation CreateTracing($feature: String!, $customerId: String!) {
        createTracing(feature: $feature, customerId: $customerId) 
    }
`;