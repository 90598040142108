import { createAction, props } from '@ngrx/store';
import {
  BusinessCustomerWithCustomerDetails,
  OwnerExclusiveAgreementPreviewData,
  RentalOfferPreviewData,
  BuyingOfferPreviewData,
  OwnerOverview,
  Customer,
} from '@frontend/models';


export const LoadOwnerOverview = createAction(
  '[OwnerOverview] Load Owner Overview',
  props<{ ownerPortalId: string }>(),
);
export const LoadOwnerOverviewFailed = createAction('[OwnerOverview] Failed to load Owner Overview');
export const LoadOwnerOverviewSucceeded = createAction(
  '[OwnerOverview] Succeeded to load Owner Overview',
  props<{ payload: OwnerOverview }>()
);

export const LoadOwnersData = createAction(
  '[OwnerOverview] Load Owners Data',
  props<{ ids: string[] }>(),
);
export const LoadOwnersDataFailed = createAction('[OwnerOverview] Failed to load Owners Data');
export const LoadOwnersDataSucceeded = createAction(
  '[OwnerOverview] Succeeded to load Owners Data',
  props<{ payload: Customer[] }>()
);

export const LoadBusinessOwnerData = createAction(
  '[OwnerOverview] Load Business Owner Data',
  props<{ id: string }>(),
);
export const LoadBusinessOwnerDataFailed = createAction('[OwnerOverview] Failed to load Business Owner Data');
export const LoadBusinessOwnerDataSucceeded = createAction(
  '[OwnerOverview] Succeeded to load Business Owner Data',
  props<{ payload: BusinessCustomerWithCustomerDetails }>()
);

export const LoadRentalOfferPreviewData = createAction(
  '[OwnerOverview] Load Rental Offer Preview Data',
  props<{ id: string }>(),
);
export const LoadRentalOfferPreviewDataFailed = createAction('[OwnerOverview] Failed to load Rental Offer Preview Data');
export const LoadRentalOfferPreviewDataSucceeded = createAction(
  '[OwnerOverview] Succeeded to load Rental Offer Preview Data',
  props<{ payload: RentalOfferPreviewData }>()
);

export const LoadExclusiveAgreementPreviewData = createAction(
  '[OwnerOverview] Load Exclusive Agreement Preview Data',
  props<{ id: string }>(),
);
export const LoadExclusiveAgreementPreviewDataFailed = createAction('[OwnerOverview] Failed to load Exclusive Agreement Preview Data');
export const LoadExclusiveAgreementPreviewDataSucceeded = createAction(
  '[OwnerOverview] Succeeded to load Exclusive Agreement Preview Data',
  props<{ payload: OwnerExclusiveAgreementPreviewData }>()
);

export const LoadBuyingOfferPreviewData = createAction(
  '[OwnerOverview] Load Buying Offer Preview Data',
  props<{ id: string }>(),
);
export const LoadBuyingOfferPreviewDataFailed = createAction('[OwnerOverview] Failed to load Buying Offer Preview Data');
export const LoadBuyingOfferPreviewDataSucceeded = createAction(
  '[OwnerOverview] Succeeded to load Buying Offer Preview Data',
  props<{ payload: BuyingOfferPreviewData }>()
);

export const UpdateCustomersData = createAction(
  '[OwnerOverview] Update Customers Data',
  props<{
    payload: {
      customers: Customer[],
      businessCustomer?: BusinessCustomerWithCustomerDetails,
    }
  }>()
);

export const UpdateRentalOfferDocumentLink = createAction(
  '[OwnerOverview] Update Rental Offer Document Link',
  props<{ url: string }>()
);

export const UpdateBuyingOfferDocumentLink = createAction(
  '[OwnerOverview] Update Buying Offer Document Link',
  props<{ url: string }>()
);

export const UpdateOwnerPortalLanguage = createAction(
  '[OwnerOverview] Update Owner Portal Language',
  props<{ ownerPortalId: string, language: string }>(),
);
export const UpdateOwnerPortalLanguageSucceeded = createAction(
  '[OwnerOverview] Update Owner Portal Language Succeeded',
  props<{ language: string }>(),
);
export const UpdateOwnerPortalLanguageFailed = createAction(
  '[OwnerOverview] Update Owner Portal Language Failed',
);
