import { gql } from 'apollo-angular';

export interface GraphQLResponseGenerateOwnerRentalOfferDocument {
  generateOwnerRentalOfferDocument: { storageUrl: string };
}

export const GENERATE_OWNER_RENTAL_OFFER_DOCUMENT = gql`
  mutation GenerateRentalOfferDocument($rentalOfferId: String!) {
    generateOwnerRentalOfferDocument(rentalOfferId: $rentalOfferId) {
      storageUrl
    }
  }
`;