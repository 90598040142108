import { gql } from 'apollo-angular';

export interface GraphQLResponseGenerateBuyingOfferDocument {
  generateBuyingOfferDocument: { storageUrl: string };
}

export const GENERATE_BUYING_OFFER_DOCUMENT = gql`
  mutation GenerateBuyingOfferDocument($accessId: String!) {
    generateBuyingOfferDocument(accessId: $accessId) {
      storageUrl
    }
  }
`;