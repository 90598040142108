
import { gql } from 'apollo-angular';
import { RentalOfferPreviewData } from '@frontend/models';

export interface GraphQLResponseRentalOfferPreviewData {
  rentalOfferPreviewData: RentalOfferPreviewData;
}

export const GET_RENTAL_OFFER_PREVIEW_DATA = gql`
  query GetRentalOfferPreviewData($id: String!) {
    rentalOfferPreviewData(id: $id) {
      agent {
        id
        email
        firstName
        lastName
        picture
        officeName
        legalEntity
        isRemax
        themeConfig
        phone
      }
      address {
        country
        city
        state
        zipCode
        streetName
        buildingNumber
        doorNumber
      }
      state
      signedDocumentUrl
    }
  }
`;