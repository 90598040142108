import { gql } from 'apollo-angular';

export interface GraphQLResponseUpdateOwnerPortalLanguage {
    updateOwnerPortalLanguage: string;
}

export const UPDATE_OWNER_PORTAL_LANGUAGE = gql`
    mutation UpdateOwnerPortalLanguage($ownerPortalId: String!, $language: String!) {
      updateOwnerPortalLanguage(ownerPortalId: $ownerPortalId, language: $language)
    }
`;