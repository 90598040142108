import { gql } from 'apollo-angular';

export interface GraphQLResponseDeleteUploadedDocument {
  deleteUploadedDocument: string;
}

export const DELETE_UPLOADED_DOCUMENT = gql`
    mutation DeleteUploadedDocument($url: String!, $type: String!, $accessId: String!) {
      deleteUploadedDocument(url: $url, type: $type, accessId: $accessId)
    }
`;

export interface GraphQLResponseDeleteExclusiveAgreementFile {
  deleteExclusiveAgreementFile: string;
}

export const DELETE_EXCLUSIVE_AGREEMENT_FILE = gql`
    mutation DeleteExclusiveAgreementFile($exclusiveAgreementId: String!, $url: String!) {
      deleteExclusiveAgreementFile(exclusiveAgreementId: $exclusiveAgreementId, url: $url)
    }
`;