import { gql } from 'apollo-angular';

export interface GraphQLResponseSendBuyingOffer {
  sendBuyingOffer: string;
}

export const SEND_BUYING_OFFER = gql`
  mutation SendBuyingOffer($accessId: String!) {
    sendBuyingOffer(accessId: $accessId)
  }
`;