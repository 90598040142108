import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { PropertyOverviewEffects } from "./effects";
import * as fromReducer from './reducer';
import { MatSnackBarModule } from "@angular/material/snack-bar";

@NgModule({
  imports: [
    CommonModule,
    MatSnackBarModule,
    EffectsModule.forFeature([PropertyOverviewEffects]),
    StoreModule.forFeature('property-overview', fromReducer.reducer),
  ]
})
export class PropertyOverviewStoreModule { }
