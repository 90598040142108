import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { OwnerOverviewActions } from './action-types';
import { CustomerService, PropertyService, ThemeService } from '@frontend/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { forkJoin } from 'rxjs';
import { TranslationService } from "../../i18n/TranslationService";

@Injectable()
export class OwnerOverviewEffects {
  public loadOwnerOverview$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OwnerOverviewActions.LoadOwnerOverview),
      switchMap(({ ownerPortalId }) =>
        this._propertyService.getOwnerOverview$(ownerPortalId).pipe(
          switchMap((data) => {
            this._themeService.setCustomerPortalLanguage(data?.agent, data?.portalLanguage)
            this._themeService.setAgentRelatedTheme(data?.agent);

            let customerLoadAction;
            if (data.customerType === 'business') {
              customerLoadAction = OwnerOverviewActions.LoadBusinessOwnerData({ id: data.businessCustomerId ?? '' });
            } else {
              customerLoadAction = OwnerOverviewActions.LoadOwnersData({ ids: data.customersId ?? [] });
            }

            return [
              OwnerOverviewActions.LoadOwnerOverviewSucceeded({ payload: data }),
              customerLoadAction,
            ]
          }),
          catchError(() => {
            const message = this._translationService.instant('alerts.property_not_found');
            const action = this._translationService.instant('alerts.close');
            this._snackBar.open(message, action);

            return [OwnerOverviewActions.LoadOwnerOverviewFailed()]
          }),
        )
      ),
    )
  );

  public loadOwnersData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OwnerOverviewActions.LoadOwnersData),
      switchMap(({ ids }) =>
        forkJoin(ids.map(id => this._customerService.getCustomerDetails$(id))).pipe(
          switchMap((data) => [
            OwnerOverviewActions.LoadOwnersDataSucceeded({ payload: data }),
          ]),
          catchError(() => [OwnerOverviewActions.LoadOwnersDataFailed()])
        )
      )
    )
  );

  public loadBusinessOwnerData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OwnerOverviewActions.LoadBusinessOwnerData),
      switchMap(({ id }) =>
        this._customerService.getBusinessCustomerDetails$(id).pipe(
          switchMap((data) => [
            OwnerOverviewActions.LoadBusinessOwnerDataSucceeded({ payload: data }),
          ]),
          catchError(() => [OwnerOverviewActions.LoadBusinessOwnerDataFailed()]),
        ),
      )
    )
  );

  public loadRentalOfferPreviewData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OwnerOverviewActions.LoadRentalOfferPreviewData),
      switchMap(({ id }) =>
        this._propertyService.getRentalOfferPreviewData$(id).pipe(
          switchMap((data) => {
            return [OwnerOverviewActions.LoadRentalOfferPreviewDataSucceeded({ payload: data })];
          }),
          catchError(() => {
            const message = this._translationService.instant('alerts.offer_loading_failed');
            const action = this._translationService.instant('alerts.close');
            this._snackBar.open(message, action, { duration: 5000 });
            return [OwnerOverviewActions.LoadRentalOfferPreviewDataFailed()];
          }),
        ),
      )
    )
  );

  public loadBuyingOfferPreviewData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OwnerOverviewActions.LoadBuyingOfferPreviewData),
      switchMap(({ id }) =>
        this._propertyService.getBuyingOfferPreviewData$(id).pipe(
          switchMap((data) => {
            return [OwnerOverviewActions.LoadBuyingOfferPreviewDataSucceeded({ payload: data })];
          }),
          catchError(() => {
            const message = this._translationService.instant('alerts.offer_loading_failed');
            const action = this._translationService.instant('alerts.close');
            this._snackBar.open(message, action, { duration: 5000 });
            return [OwnerOverviewActions.LoadBuyingOfferPreviewDataFailed()];
          }),
        ),
      )
    )
  );

  public loadExclusiveAgreementPreviewData$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OwnerOverviewActions.LoadExclusiveAgreementPreviewData),
      switchMap(({ id }) =>
        this._propertyService.getOwnerExclusiveAgreementPreviewData$(id).pipe(
          switchMap((data) => {
            return [OwnerOverviewActions.LoadExclusiveAgreementPreviewDataSucceeded({ payload: data })];
          }),
          catchError(() => {
            const message = this._translationService.instant('alerts.offer_loading_failed');
            const action = this._translationService.instant('alerts.close');
            this._snackBar.open(message, action, { duration: 5000 });
            return [OwnerOverviewActions.LoadExclusiveAgreementPreviewDataFailed()];
          }),
        ),
      )
    )
  );

  public updateOwnerPortalLanguage$ = createEffect(() =>
    this._actions$.pipe(
      ofType(OwnerOverviewActions.UpdateOwnerPortalLanguage),
      switchMap(({ ownerPortalId, language }) =>
        this._propertyService.updateOwnerPortalLanguage$(ownerPortalId, language).pipe(
          switchMap(() => {
            this._themeService.setCustomerPortalLanguage(undefined, language);
            return [OwnerOverviewActions.UpdateOwnerPortalLanguageSucceeded({ language })];
          }),
          catchError(() => [OwnerOverviewActions.UpdateOwnerPortalLanguageFailed()]),
        )
      )
    )
  );

  constructor(
    private readonly _actions$: Actions,
    private readonly _snackBar: MatSnackBar,
    private readonly _themeService: ThemeService,
    private readonly _customerService: CustomerService,
    private readonly _propertyService: PropertyService,
    private _translationService: TranslationService,
  ) { }
}
