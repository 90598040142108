import {PropertyOverview} from "@frontend/models";
import {createReducer, on} from "@ngrx/store";
import {PublicPropertyOverviewActions} from "@frontend/store/public-property-overview/action-types";

export interface State {
    publicPropertyOverview: {
        data: PropertyOverview | null;
        error: boolean;
        loading: boolean;
    };
}

export const initialState: State = {
    publicPropertyOverview: {
        data: null,
        error: false,
        loading: false,
    },
};

const publicPropertyOverviewReducer = createReducer(
    initialState,

    on(PublicPropertyOverviewActions.LoadPublicPropertyOverview, (state) => ({
        ...state,
        publicPropertyOverview: {
            ...state.publicPropertyOverview,
            loading: true,
            error: false,
        },
    })),
    on(PublicPropertyOverviewActions.LoadPublicPropertyOverviewSucceeded, (state, {payload}) => ({
        ...state,
        publicPropertyOverview: {
            ...state.publicPropertyOverview,
            loading: false,
            data: payload,
        },
    })),
    on(PublicPropertyOverviewActions.LoadPublicPropertyOverviewFailed, (state) => ({
        ...state,
        publicPropertyOverview: {
            ...state.publicPropertyOverview,
            loading: false,
            error: true,
        },
    })),
);

export const reducer = (state: State | undefined, action: any) => publicPropertyOverviewReducer(state, action);

export const getPublicPropertyOverview = (state: State) => state.publicPropertyOverview.data;
export const getPublicPropertyOverviewError = (state: State) => state.publicPropertyOverview.error;
export const getPublicPropertyOverviewLoading = (state: State) => state.publicPropertyOverview.loading;