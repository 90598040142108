import { gql } from 'apollo-angular';

export interface GraphQLResponseTrackDocumentDownload {
  trackDocumentDownload: string;
}

export const TRACK_DOCUMENT_DOWNLOAD = gql`
    mutation TrackDocumentDownload($accessId: String!, $data: TrackDocumentDownloadInput!) {
        trackDocumentDownload(accessId: $accessId, data: $data)
    }
`;