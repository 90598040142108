import { gql } from 'apollo-angular';

export interface GraphQLResponseUpdateBuyingOffer {
  updateBuyingOffer: string;
}

export const UPDATE_BUYING_OFFER = gql`
    mutation UpdateBuyingOffer($accessId: String!, $data: BuyingOfferUpdateInput!) {
      updateBuyingOffer(accessId: $accessId, data: $data)
    }
`;