import {Injectable} from "@angular/core";
import {ActionsSubject, Store} from "@ngrx/store";
import {
    PublicPropertyOverviewActions,
    PublicPropertyOverviewFeatureState,
    publicPropertyOverviewSelectors
} from "@frontend/store/public-property-overview/index";

@Injectable({providedIn: 'root'})
export class PublicPropertyOverviewStoreFacade {

    publicPropertyOverview$ = this._store$.select(publicPropertyOverviewSelectors.publicPropertyOverview);
    publicPropertyOverviewLoading$ = this._store$.select(publicPropertyOverviewSelectors.publicPropertyOverviewLoading);
    publicPropertyOverviewError$ = this._store$.select(publicPropertyOverviewSelectors.publicPropertyOverviewError);

    constructor(
        private readonly _store$: Store<PublicPropertyOverviewFeatureState>,
        private readonly actionsListener$: ActionsSubject,
    ) {
    }

    loadPublicPropertyOverview(objectId: string): void {
        this._store$.dispatch(PublicPropertyOverviewActions.LoadPublicPropertyOverview({objectId}));
    }
}