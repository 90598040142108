import { gql } from 'apollo-angular';

export interface GraphQLResponseUpdatePropertyOverview {
  updatePropertyOverview: string;
}

export const UPDATE_PROPERTY_OVERVIEW = gql`
    mutation UpdatePropertyOverview($accessId: String!, $data: PropertyOverviewUpdateInput!) {
      updatePropertyOverview(accessId: $accessId, data: $data)
    }
`;