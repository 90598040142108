import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GraphQLResponseTrackDocumentDownload, TRACK_DOCUMENT_DOWNLOAD } from '@frontend/graphql/mutation';
import { TrackDocumentDownload } from '@frontend/models';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import {
  DOWNLOAD_ALL_DOCUMENTS,
  GraphQLResponseDownloadAllDocuments
} from "../graphql/schema/query/download-all-documents";

@Injectable({ providedIn: 'root' })
export class DocumentService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _apollo: Apollo,
  ) { }

  openFile(url: string): void {
    if (url.startsWith('https://propup-media-files')) {
      this.openWindowWithLink(url);
    } else {
      this.openPdfAsBlobUrlInNewTabNow(url);
    }
  }

  openWindowWithLink(url: string): void {
    window.open(url, '_blank');
  }

  openPdfAsBlobUrlInNewTabNow(url: string): void {
    this.openPdfAsBlobUrlInNewTab(url).subscribe();
  }

  openPdfAsBlobUrlInNewTab(url: string): Observable<string> {
    return this._loadPdf(url).pipe(
      first(),
      map(this._blobToObjectUrl),
      tap(this.openWindowWithLink),
    );
  }

  trackDocumentDownload$(accessId: string, data: TrackDocumentDownload): Observable<string> {
    return this._apollo.mutate<
      GraphQLResponseTrackDocumentDownload,
      { accessId: string; data: TrackDocumentDownload }
    >({
      mutation: TRACK_DOCUMENT_DOWNLOAD,
      variables: { accessId, data },
    }).pipe(map((m) => m.data!.trackDocumentDownload));
  }

  private _loadPdf(url: string): Observable<Blob> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');

    return this._http.get(url, { headers, responseType: 'blob' }).pipe(
      first(),
      map((res) => new Blob([res], { type: 'application/pdf' })),
    );
  }

  private _blobToObjectUrl(blob: Blob): string {
    return URL.createObjectURL(blob);
  }

  downloadAllDocuments$(
      filesToZip: string[],
      fileNames: string[],
  ): Observable<string> {
    return this._apollo.query<
        GraphQLResponseDownloadAllDocuments,
        { filesToZip: string[]; fileNames: string[] }
    >({
      query: DOWNLOAD_ALL_DOCUMENTS,
      variables: { filesToZip, fileNames },
    }).pipe(map((m) => m.data!.downloadAllDocuments));
  }
}
