import { gql } from 'apollo-angular';
import { SmsVerificationStatus } from "@frontend/models";

export interface GraphQLResponseSmsVerificationStatus {
  smsVerificationStatus: SmsVerificationStatus;
}

export const SMS_VERIFICATION_STATUS = gql`
  query GetSmsVerificationStatus($model: SmsVerificationInput!) {
    smsVerificationStatus(model: $model) {
      isVerified
    }
  }
`;