import { createReducer, on } from '@ngrx/store';
import { OwnerOverviewActions } from './action-types';
import {
  BusinessCustomerWithCustomerDetails,
  BuyingOfferPreviewData,
  Customer,
  OwnerExclusiveAgreementPreviewData,
  OwnerOverview,
  RentalOfferPreviewData,
} from '@frontend/models';

export interface State {
  ownerOverview: {
    data: OwnerOverview | null;
    error: boolean;
    loading: boolean;
  };
  ownersData: {
    customerType: string;
    businessCustomer?: BusinessCustomerWithCustomerDetails | null;
    customers: Customer[];
  } | null;
  rentalOfferPreviewData: RentalOfferPreviewData | null;
  buyingOfferPreviewData: BuyingOfferPreviewData | null;
  exclusiveAgreementPreviewData: OwnerExclusiveAgreementPreviewData | null;
}

export const initialState: State = {
  ownerOverview: {
    data: null,
    error: false,
    loading: false,
  },
  ownersData: null,
  rentalOfferPreviewData: null,
  buyingOfferPreviewData: null,
  exclusiveAgreementPreviewData: null,
};

const ownerOverviewReducer = createReducer(
  initialState,

  on(OwnerOverviewActions.LoadOwnerOverview, (state) => ({
    ...state,
    ownerOverview: {
      ...state.ownerOverview,
      loading: true,
      error: false,
    },
  })),
  on(OwnerOverviewActions.LoadOwnerOverviewSucceeded, (state, { payload }) => ({
    ...state,
    ownerOverview: {
      loading: false,
      error: false,
      data: payload,
    },
  })),
  on(OwnerOverviewActions.LoadOwnerOverviewFailed, (state) => ({
    ...state,
    ownerOverview: {
      ...state.ownerOverview,
      error: true,
      loading: false,
    },
  })),

  on(OwnerOverviewActions.LoadOwnersDataSucceeded, (state, { payload }) => ({
    ...state,
    ownersData: {
      customerType: 'private',
      customers: payload,
    },
  })),
  on(OwnerOverviewActions.LoadOwnersDataFailed, (state) => ({
    ...state,
    ownersData: {
      customerType: 'private',
      customers: [],
    },
  })),

  on(OwnerOverviewActions.LoadBusinessOwnerDataSucceeded, (state, { payload }) => ({
    ...state,
    ownersData: {
      customerType: 'business',
      customers: payload.customers,
      businessCustomer: payload,
    },
  })),
  on(OwnerOverviewActions.LoadBusinessOwnerDataFailed, (state) => ({
    ...state,
    ownersData: {
      customerType: 'business',
      customers: [],
      businessCustomer: null
    },
  })),

  on(OwnerOverviewActions.LoadRentalOfferPreviewDataSucceeded, (state, { payload }) => ({
    ...state,
    rentalOfferPreviewData: payload,
  })),
  on(OwnerOverviewActions.LoadRentalOfferPreviewDataFailed, (state) => ({
    ...state,
    rentalOfferPreviewData: null,
  })),

  on(OwnerOverviewActions.LoadBuyingOfferPreviewDataSucceeded, (state, { payload }) => ({
    ...state,
    buyingOfferPreviewData: payload,
  })),
  on(OwnerOverviewActions.LoadBuyingOfferPreviewDataFailed, (state) => ({
    ...state,
    buyingOfferPreviewData: null,
  })),

  on(OwnerOverviewActions.LoadExclusiveAgreementPreviewDataSucceeded, (state, { payload }) => ({
    ...state,
    exclusiveAgreementPreviewData: payload,
  })),
  on(OwnerOverviewActions.LoadExclusiveAgreementPreviewDataFailed, (state) => ({
    ...state,
    exclusiveAgreementPreviewData: null,
  })),

  on(OwnerOverviewActions.UpdateCustomersData, (state, { payload }) => ({
    ...state,
    ownersData: {
      customerType: payload.businessCustomer ? 'business' : 'private',
      customers: payload.customers,
      businessCustomer: payload.businessCustomer || null,
    },
  })),

  on(OwnerOverviewActions.UpdateRentalOfferDocumentLink, (state, { url }) => ({
    ...state,
    rentalOfferPreviewData: {
      ...state.rentalOfferPreviewData as RentalOfferPreviewData,
      signedDocumentUrl: url,
    },
  })),

  on(OwnerOverviewActions.UpdateBuyingOfferDocumentLink, (state, { url }) => ({
    ...state,
    buyingOfferPreviewData: {
      ...state.buyingOfferPreviewData as BuyingOfferPreviewData,
      signedDocumentUrl: url,
    },
  })),
);

export const reducer = (state: State | undefined, action: any) =>
  ownerOverviewReducer(state, action);

export const getOwnerOverview = (state: State) => state.ownerOverview.data;
export const getOwnerOverviewError = (state: State) => state.ownerOverview.error;
export const getOwnerOverviewLoading = (state: State) => state.ownerOverview.loading;
export const getOwnersData = (state: State) => state.ownersData;
export const getRentalOfferPreviewData = (state: State) => state.rentalOfferPreviewData;
export const getBuyingOfferPreviewData = (state: State) => state.buyingOfferPreviewData;
export const getExclusiveAgreementPreviewData = (state: State) => state.exclusiveAgreementPreviewData;

