import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable, map } from 'rxjs';

import {
  BusinessCustomer,
  BusinessCustomerWithCustomerDetails,
  Customer,
  SmsVerificationModel,
  SmsVerificationStatus,
  VerifyCodeModel,
} from '@frontend/models';
import {
  GET_CUSTOMER_DETAILS,
  GraphQLResponseCustomerDetails,
  GraphQLResponseSmsVerificationStatus,
  SMS_VERIFICATION_STATUS as GET_SMS_VERIFICATION_STATUS,
  GraphQLResponseBusinessCustomerDetails,
  GET_BUSINESS_CUSTOMER_DETAILS,
} from '@frontend/graphql/query';
import {
  SAVE_CUSTOMER_DETAILS,
  GraphQLResponseSaveCustomerDetails,
  GraphQLResponseRequestSmsVerification,
  REQUEST_SMS_VERIFICATION,
  VERIFY_SMS,
  GraphQLResponseVerifySms,
  GraphQLResponseSaveBusinessCustomerDetails,
  SAVE_BUSINESS_CUSTOMER_DETAILS,
} from '@frontend/graphql/mutation';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  constructor(private readonly _apollo: Apollo) { }

  getBusinessCustomerDetails$(id: string): Observable<BusinessCustomerWithCustomerDetails> {
    return this._apollo.query<
      GraphQLResponseBusinessCustomerDetails,
      { id: string }
    >({
      query: GET_BUSINESS_CUSTOMER_DETAILS,
      variables: { id },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.businessCustomerDetails));
  }

  saveBusinessCustomerDetails$(data: BusinessCustomer): Observable<BusinessCustomerWithCustomerDetails> {
    return this._apollo.mutate<
      GraphQLResponseSaveBusinessCustomerDetails,
      { customer: BusinessCustomer }
    >({
      mutation: SAVE_BUSINESS_CUSTOMER_DETAILS,
      variables: { customer: data },
    }).pipe(map((m: any) => m.data.saveBusinessCustomerDetails));
  }

  getCustomerDetails$(id: string): Observable<Customer> {
    return this._apollo.query<
      GraphQLResponseCustomerDetails,
      { id: string }
    >({
      query: GET_CUSTOMER_DETAILS,
      variables: { id },
      fetchPolicy: 'no-cache',
    }).pipe(map((m) => m.data.customerDetails));
  }

  saveCustomerDetails$(data: Customer, agentId?: string): Observable<Customer> {
    return this._apollo.mutate<
      GraphQLResponseSaveCustomerDetails,
      { customer: Customer, agentId: string }
    >({
      mutation: SAVE_CUSTOMER_DETAILS,
      variables: { customer: data, agentId: agentId ?? '' },
    }).pipe(map((m: any) => m.data.saveCustomerDetails));
  }

  requestSmsVerification$(model: SmsVerificationModel): Observable<VerifyCodeModel> {
    return this._apollo.mutate<
      GraphQLResponseRequestSmsVerification,
      { model: SmsVerificationModel }
    >({
      mutation: REQUEST_SMS_VERIFICATION,
      variables: { model },
    }).pipe(map((m: any) => m.data.requestSmsVerification));
  }

  verifySms$(model: VerifyCodeModel): Observable<SmsVerificationStatus> {
    return this._apollo.mutate<
      GraphQLResponseVerifySms,
      { model: VerifyCodeModel }
    >({
      mutation: VERIFY_SMS,
      variables: { model },
    }).pipe(map((m: any) => m.data.verifySms));
  }

  getSmsVerificationStatus$(model: SmsVerificationModel): Observable<SmsVerificationStatus> {
    return this._apollo.mutate<
      GraphQLResponseSmsVerificationStatus,
      { model: SmsVerificationModel }
    >({
      mutation: GET_SMS_VERIFICATION_STATUS,
      variables: { model },
    }).pipe(map((m: any) => m.data.smsVerificationStatus));
  }
}