import { gql } from 'apollo-angular';

export interface GraphQLResponseCreateOverviewFeedback {
  createOverviewFeedback: string;
}

export const CREATE_OVERVIEW_FEEDBACK = gql`
    mutation CreateOverviewFeedback($accessId: String!, $data: OverviewFeedbackInput!) {
        createOverviewFeedback(accessId: $accessId, data: $data)
    }
`;