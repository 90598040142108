import { gql } from 'apollo-angular';

export interface GraphQLResponseSendRentalOffer {
  sendRentalOffer: string;
}

export const SEND_RENTAL_OFFER = gql`
  mutation SendRentalOffer($accessId: String!) {
    sendRentalOffer(accessId: $accessId)
  }
`;