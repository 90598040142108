import { gql } from 'apollo-angular';

export interface GraphQLResponseGenerateViewingPassDocument {
  generateViewingPassDocument: { storageUrl: string };
}

export const GENERATE_VIEWING_PASS_DOCUMENT = gql`
  mutation GenerateViewingPassDocument($accessId: String!) {
    generateViewingPassDocument(accessId: $accessId) {
      storageUrl
    }
  }
`;