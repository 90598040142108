import { AppModule } from "./app/app.module";
import { environment} from "./environments/environment";
import {enableProdMode} from "@angular/core";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";
import * as Sentry from "@sentry/angular-ivy";
import {BrowserTracing} from "@sentry/angular";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";

if (environment.staticToggles.enableLoggingTracing) {
  Sentry.init({
    dsn: 'https://29c2d5eafe0a445ca9f93e84f5f5d6bb@o1307589.ingest.sentry.io/4505079466360832',
    environment: environment.SENTRY_ENVIRONMENT,
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['localhost', 'https://app.propup.at'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new CaptureConsoleIntegration({levels: ['error']}),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}


if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));