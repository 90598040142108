import { gql } from 'apollo-angular';

export interface GraphQLResponseSignOwnerBuyingOfferDocument {
  signOwnerBuyingOfferDocument: { storageUrl: string };
}

export const SIGN_OWNER_BUYING_OFFER_DOCUMENT = gql`
  mutation SignBuyingOfferDocument($buyingOfferId: String!, $sellersAcceptance: SellersAcceptanceInput!) {
    signOwnerBuyingOfferDocument(buyingOfferId: $buyingOfferId, sellersAcceptance: $sellersAcceptance) {
      storageUrl
    }
  }
`;