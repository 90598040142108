import { NgModule } from '@angular/core';

import { HomeComponent } from './home.component';
import { RouterLink } from "@angular/router";

@NgModule({
    imports: [RouterLink],
    exports: [],
    declarations: [HomeComponent],
    providers: [],
})
export class HomeModule {}
