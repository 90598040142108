import { gql } from 'apollo-angular';

export interface GraphQLResponseSendBuyingCounterOfferDocument {
  sendBuyingCounterOfferDocument: { storageUrl: string };
}

export const SEND_BUYING_COUNTER_OFFER_DOCUMENT = gql`
  mutation SignBuyingOfferDocument($buyingOfferId: String!, $buyersAcceptance: BuyersAcceptanceWithChangeInput!) {
    sendBuyingCounterOfferDocument(buyingOfferId: $buyingOfferId, buyersAcceptance: $buyersAcceptance) {
      storageUrl
    }
  }
`;