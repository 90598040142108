import { gql } from 'apollo-angular';

export interface GraphQlResponseSaveOwnerDocuments {
  saveOwnerDocuments: string;
}

export const SAVE_OWNER_DOCUMENTS = gql`
  mutation SaveOwnerDocuments($ownerPortalId: String!, $documents: [SaveStorageUrlInput!]!) {
    saveOwnerDocuments(ownerPortalId: $ownerPortalId, documents: $documents)
  }
`;
